import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <div className="container mx-auto md:pt-40 w-4/6">
        <h2 className="font-serif text-center uppercase">Hello, we are</h2>
        <h1 className="font-serif text-center uppercase">Mary & Maurizio</h1>
        <h3 className="font-serif text-center ">Husband & wife,</h3>
        <h3 className="font-serif text-center ">Art directors & Photographers,</h3>
        <h3 className="font-serif text-center ">living in Naples, Italy.</h3>
        <div className="w-1/2	h-16 mb-10 mx-auto border-b-2 border-background"></div>

        <div className="container mx-auto max-w-1/2">
          <p className="text-center">Feel free to write us a line</p>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className="container mx-auto w-80"> 
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
            <label>
              <input type="text" name="name" id="name" placeholder="Name" className="px-3 py-4 my-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white text-sm border-b-2 border-grey outline-none focus:outline-none focus:shadow-outline w-full" />
            </label>
            <label>
              <input type="email" name="email" id="email" placeholder="E-mail" className="px-3 py-4 my-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white text-sm border-b-2 border-grey outline-none focus:outline-none focus:shadow-outline w-full" />
            </label>
            <label>
              <textarea name="message" id="message" rows="5" placeholder="Message" className="px-4 py-3 my-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white text-sm border-b-2 border-grey outline-none focus:outline-none focus:shadow-outline w-full" />
            </label>
            <button type="submit" className="bg-background text-white font-serif tracking-widest active:bg-gray-600 uppercase text-base px-8 py-3 my-4 mx-auto shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full" style={{ transition: "all .15s ease" }}>Send</button>
          </form>
        </div>

      </div>
      
     
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
